<template>
  <div
    class="rs-report"
    :class="pdfType ? `is-pdf-${pdfType}` : ''"
    v-resize="onResize"
  >
    <div
      v-if="loading"
      class="d-flex flex-column align-center justify-center mt-12"
    >
      <h3 class="mb-8">Loading your Risk Score...</h3>
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-if="!loading && !report">
      <v-container class="mt-7">
        <v-row class="align-center">
          <v-col class="text-center">
            <v-card
              v-if="message"
              outlined
              max-width="600"
              elevation="12"
              class="mx-auto py-8"
            >
              <v-card-text>
                <h3>
                  {{ message }}
                </h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-if="!loading && report">
      <!-- <v-system-bar
        color="rgba(255, 255, 255, 0.1)"
        class="mb-2 py-4 text-right no-print"
      >
        <v-container>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><v-btn icon v-bind="attrs" v-on="on" class="mr-4">
                <v-icon
                  color="white"
                  title="Download"
                  @click="handlePdf('download')"
                  >mdi-download-circle</v-icon
                >
              </v-btn>
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"
              ><v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  color="white"
                  title="Print"
                  class="mr-0"
                  @click="handlePdf('print')"
                  >mdi-printer</v-icon
                >
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
        </v-container>
      </v-system-bar> -->
      <v-container>
        <ReportTemplateHeader
          :reportVersion="report._version"
          :reportDate="report._generated"
          :prospectQualified="reportInput.prospect.qualified"
          :prospectName="prospectName"
          :advisor="reportInput.advisor"
          :pdfType="pdfType"
        />

        <!-- Change Report Template Content based on Report Template -->

        <div v-if="sectionsLoaded" class="rs-report__content">
          <section
            v-for="section in reportSections"
            :key="section.id"
            :class="pdfType ? 'mb-6' : 'mb-8 mb-lg-12'"
          >
            <component
              :is="section.component"
              :isEditing="false"
              :isMobile="isMobile"
              :value="section.value"
              :advisor="reportInput.advisor"
              :prospectId="prospectId"
              :pdfType="pdfType"
            />
          </section>
        </div>
      </v-container>
    </template>

    <v-dialog v-model="dialogPdf" persistent width="600px">
      <v-card>
        <v-card-text>
          <v-container>
            <div class="d-flex flex-column align-center justify-center my-12">
              <h3 v-if="dialogPdfErrorText">{{ dialogPdfErrorText }}</h3>
              <h3 v-else class="mb-8">
                Please wait while your {{ dialogPdfTypeText }} PDF is
                generated...
              </h3>
              <v-btn
                v-if="dialogPdfErrorText"
                class="mt-8"
                color="error"
                @click="dialogPdf = false"
                >Close</v-btn
              >
              <v-progress-circular
                v-else
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce as _debounce } from "lodash";
import { mask } from "maska";
import { API } from "aws-amplify";
import { customGetAdvisorReportTemplate } from "@/customGraphQL/customQueries";

import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

import ReportTemplateHeader from "@/components/ReportTemplateHeader";

export default {
  mixins: [getUserCredentialsMixin],
  components: {
    ReportTemplateHeader,
  },
  data() {
    return {
      loading: false,
      report: null,
      reportTemplate: null,
      prospectId: null,
      message: "",
      isMobile: null,
      pdfType: null,
      dialogPdf: false,
      dialogPdfTypeText: "",
      dialogPdfErrorText: "",
      sectionsLoaded: false,
      scoreReportTemplateId: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    next((vm) => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      // clear your previous data.
      // re-populate data
      if (to.query.pdfType) {
        vm.pdfType = to.query.pdfType;
      }

      // vm.getCredentials();
      vm.prospectId = to.params.id;
      // vm.advisorVanityName = to.params.advisorVanityName
    });
  },
  async beforeRouteUpdate() {
    // react to route changes...
    // this.getData(to.params.id);
    this.getCredentials();
  },
  async mounted() {
    try {
      await this.getCredentials();
      this.scoreReportTemplateId =
        await this.getProspectAdvisorReportTemplate();

      if (this.scoreReportTemplateId) {
        this.getData();
      }
    } catch (error) {
      console.log("REPORT MOUNT ERROR: ", error);
    }
  },
  computed: {
    showAdvisorInfo() {
      // Only show advisor info if there is an advisor and the current prospect is qualified
      if (this.report.data.advisor && this.report.data.prospect.qualified) {
        return true;
      } else {
        return false;
      }
    },
    prospectName() {
      let firstName = null;
      let lastName = null;
      let fullName = null;

      if (this.reportInput.prospect) {
        if (this.reportInput.prospect.first_name) {
          firstName = this.reportInput.prospect.first_name;

          if (this.reportInput.prospect.last_name) {
            lastName = this.reportInput.prospect.last_name;
          }

          fullName = `${firstName ? firstName : ""} ${
            lastName ? lastName : ""
          }`;
        }
      }
      return fullName;
    },
  },
  methods: {
    onResize: _debounce(async function () {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }, 500),
    async getProspectAdvisorReportTemplate() {
      try {
        const res = await API.graphql({
          query: customGetAdvisorReportTemplate,
          variables: { id: this.prospectId },
          authMode: this.authMode,
        });

        if (res && res.data?.getProspect?.advisor?.scoreReportTemplateId) {
          return res.data.getProspect.advisor.scoreReportTemplateId;
        } else {
          this.message =
            "Sorry, we are unable to locate the proper report template. Please contact your advisor for assistance.";
          return null;
        }
      } catch (error) {
        console.log(
          "Error getting prospect's advisor's score report template.",
          error
        );
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      }
    },
    async setSectionComponents() {
      // Set the component imports for each section for they dynamic component element
      await Promise.all(
        this.reportSections.map((section) => {
          section["component"] = () =>
            import(
              `@/components/reportTemplateBuilder/sections/${section.componentName}.vue`
            );
        })
      );

      this.sectionsLoaded = true;
    },
    formatPhone(phone) {
      if (!phone) {
        return "";
      }
      return mask(phone, "###-###-####");
    },
    async getData() {
      const prospectId = this.prospectId;

      if (!prospectId) {
        this.message =
          "Sorry, the score report you are trying to reach is invalid. Please contact your advisor for assistance.";
        return;
      } else {
        this.fetchReport(prospectId);
      }
    },
    fetchReport(prospectId) {
      // Fetch score by submitting scoring params
      this.loading = true;
      // Add route path to data for api use - ex: your-score-report
      // const reportRoutePath = this.$route.path.split("/")[1];
      // data.reportRoutePath = reportRoutePath;

      fetch(process.env.VUE_APP_SCORE_API + "score_report_v4", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prospect_id: prospectId,
          template_id: this.scoreReportTemplateId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong fetching report", response);
          }
        })
        .then((data) => {
          if (data.success) {
            // console.log("data report", data);
            if (!data.report || data.report.template.length < 1) {
              this.message =
                "Sorry, we were unable to locate your report. Please refresh to try again or contact your advisor for assistance.";
            } else {
              this.report = data.report;

              this.reportInput = data.input;

              this.reportTemplate = this.report.template[0].content;

              this.reportSections = this.reportTemplate;
              console.log("reportSections", this.reportSections);
              this.setSectionComponents();
            }
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);

          this.loading = false;
          if (!this.report) {
            this.message = error.message;
          }
        });
    },

    async handlePdf(pdfType) {
      this.dialogPdfErrorText = "";
      this.dialogPdfTypeText =
        pdfType === "print" ? "print-ready" : "downloadable";
      this.dialogPdf = true;

      try {
        const response = await fetch(
          `${process.env.VUE_APP_PDF_API}?report_id=${this.prospectId}&pdfType=${pdfType}`
        );

        const data = await response.json();

        if (!data.pdfUrl) {
          throw Error(
            "Sorry, your PDF could not be generated. Please try again."
          );
        }
        this.dialogPdfTypeText = "";
        this.dialogPdf = false;

        window.open(data.pdfUrl, "_blank");
      } catch (error) {
        console.log(error);
        this.dialogPdfErrorText = error;
      }
    },
  },
};
</script>
